import { Builder } from '@builder.io/react'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useRef } from 'react'
import './index.css'

enum WidgetType {
  Carousel = 'carousel',
  MicroCombo = 'microCombo',
  List = 'list'
}

declare global {
  interface Window {
    Trustpilot: any
  }
}

interface Props {
  backgroundColor: string
  widgetType: WidgetType
  tags: string
}

const TrustPilotCombo = ({ backgroundColor, widgetType, tags }: Props) => {
  useEffect(() => {
    console.log('TrustPilotCarousel::Change', window, window.Trustpilot)
    if (widgetType == WidgetType.Carousel) {
      const trustCarousel = document.getElementById('trust-pilot-carousel')
      window.Trustpilot?.loadFromElement(trustCarousel)
    } else if (widgetType == WidgetType.MicroCombo) {
      const trustWidget = document.getElementById('trust-pilot-micro')
      window.Trustpilot?.loadFromElement(trustWidget)
    } else if (widgetType == WidgetType.List) {
      const trustWidget = document.getElementById('trust-pilot-list')
      window.Trustpilot?.loadFromElement(trustWidget)
    }
  }, [widgetType])

  const getWidget = () => {
    if (widgetType == WidgetType.MicroCombo) {
      return (
        <div key="micro-combo">
          {/* <!-- TrustBox widget - Micro Combo --> */}
          <div
            className="trustpilot-widget flex px-8 py-4"
            data-locale="en-GB"
            // 53aa8807dec7e10d38f59f32
            data-template-id="5419b6ffb0d04a076446a9af"
            data-businessunit-id="5af423d4a3303b0001bf47c1"
            // data-style-height="20px"
            data-style-width="100%"
            data-theme="light"
            data-font-family="Montserrat"
            data-text-color="#004355"
            data-stars="5"
            data-tags={tags ? tags : ''}
            style={{ backgroundColor }}
            id="trust-pilot-micro"
          >
            <a href="https://uk.trustpilot.com/review/differentdog.com" target="_blank" rel="noreferrer noopener">
              <StaticImage
                objectFit="contain"
                className="w-full"
                src="../../../images/trustpilot.png"
                alt="Trustpilot"
              />
            </a>
          </div>
          {/* <!-- End TrustBox widget --> */}
        </div>
      )
    }

    if (widgetType == WidgetType.List) {
      return (
        // <!-- TrustBox widget - List -->
        <div
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="539ad60defb9600b94d7df2c"
          data-businessunit-id="5af423d4a3303b0001bf47c1"
          data-style-height="500px"
          data-style-width="100%"
          data-theme="light"
          data-stars="5"
          data-review-languages="en"
          data-font-family="Montserrat"
          data-text-color="#004355"
          data-tags={tags ? tags : ''}
          id="trust-pilot-list"
          style={{ backgroundColor }}
        >
          <a href="https://uk.trustpilot.com/review/differentdog.com" target="_blank" rel="noreferrer noopener">
            <StaticImage objectFit="contain" className="w-full" src="../../../images/trustpilot.png" alt="Trustpilot" />
          </a>
        </div>
        // <!-- End TrustBox widget -->
      )
    }

    return (
      <div key="trust-carousel">
        {/* <!-- TrustBox widget - Carousel --> */}
        <div
          className="trustpilot-widget px-0 py-4 sm:px-8"
          data-locale="en-GB"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="5af423d4a3303b0001bf47c1"
          data-style-height="140px"
          data-style-width="100%"
          data-theme="light"
          data-stars="5"
          data-review-languages="en"
          data-font-family="Montserrat"
          data-text-color="#004355"
          data-tags={tags ? tags : ''}
          id="trust-pilot-carousel"
          style={{ backgroundColor }}
        >
          <a href="https://uk.trustpilot.com/review/differentdog.com" target="_blank" rel="noreferrer noopener">
            <StaticImage objectFit="contain" className="w-full" src="../../../images/trustpilot.png" alt="Trustpilot" />
          </a>
        </div>
        {/* <!-- End TrustBox widget --> */}
      </div>
    )
  }

  return (
    <div className="h-full w-full px-10" style={{ backgroundColor }}>
      {getWidget()}
    </div>
  )
}

export default TrustPilotCombo
